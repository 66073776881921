import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case } from '../../../models/Case/case';

interface AgencySectionProps {
  register: UseFormRegister<Case>;
  errors: FieldErrors<Case>;
}

const AgencySection: React.FC<AgencySectionProps> = ({ register, errors }) => (
  <div className="form-section">
    <h3>Agency</h3>
    <div className="form-row">
      <div className="form-group case-contact contact-type">
        <label>Case Manager</label>
      </div>
      <div className="form-group">
        <label htmlFor="agentfirstName">First Name</label>
        <input id="agentfirstName" {...register('agentfirstName')} />
        {errors.agentfirstName && <span style={{ color: 'red' }}>{errors.agentfirstName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agentlastName">Last Name</label>
        <input id="agentlastName" {...register('agentlastName')} />
        {errors.agentlastName && <span style={{ color: 'red' }}>{errors.agentlastName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agentEmail">Email</label>
        <input id="agentEmail" type="email" {...register('agentEmail')} />
        {errors.agentEmail && <span style={{ color: 'red' }}>{errors.agentEmail.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="agency">Agency/BGA</label>
        <input id="agency" {...register('agency')} />
        {errors.agency && <span style={{ color: 'red' }}>{errors.agency.message}</span>}
      </div>
    </div>

    <div className="form-row">
      <div className="form-group contact-type">
        <label>Agent</label>
      </div>
      <div className="form-group">
        <input id="agent1.firstName" {...register('agent1.firstName')} />
        {errors.agent1?.firstName && <span style={{ color: 'red' }}>{errors.agent1?.firstName.message}</span>}
      </div>
      <div className="form-group">
        <input id="agent1.lastName" {...register('agent1.lastName')} />
        {errors.agent1?.lastName && <span style={{ color: 'red' }}>{errors.agent1.lastName.message}</span>}
      </div>
      <div className="form-group">
        <input id="agent1.email" type="email" {...register('agent1.email')} />
        {errors.agent1?.email && <span style={{ color: 'red' }}>{errors.agent1?.email.message}</span>}
      </div>
      <div className="form-group">
        <input id="agent1.agency" {...register('agent1.agency')} />
        {errors.agent1?.agency && <span style={{ color: 'red' }}>{errors.agent1.agency.message}</span>}
      </div>
    </div>

    <div className="form-row">
      <div className="form-group contact-type">
        <label>Marketing Contact</label>
      </div>
      <div className="form-group">
        <input id="contact.firstName" {...register('contact.firstName')} />
        {errors.contact?.firstName && <span style={{ color: 'red' }}>{errors.contact?.firstName.message}</span>}
      </div>
      <div className="form-group">
        <input id="contact.lastName" {...register('contact.lastName')} />
        {errors.contact?.lastName && <span style={{ color: 'red' }}>{errors.contact.lastName.message}</span>}
      </div>
      <div className="form-group">
        <input id="contact.email" type="email" {...register('contact.email')} />
        {errors.contact?.email && <span style={{ color: 'red' }}>{errors.contact?.email.message}</span>}
      </div>
      <div className="form-group">
        <input id="contact.agency" {...register('contact.agency')} />
        {errors.contact?.agency && <span style={{ color: 'red' }}>{errors.contact.agency.message}</span>}
      </div>
    </div>
  </div>
);

export default AgencySection;
