import  { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { carrierCaseManagerFetchCases } from '../../services/case-data.service';

import "./carriercasemanager.css";
import { Button } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ExchangeRelay } from '../../models/exchangeRelay';
import { Case } from '../../models/Case/case';
import { carrierCaseManagerFetchRelays } from '../../services/exchangeRelay-data.service';
import OfferDetail from '../../components/OfferDetail/offerdetail';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { SummaryStatus } from '../../types/caseTypes';

const CarrierCaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);
  
  const [relays, setRelays] = useState<ExchangeRelay[]>([]);
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState('');
	const [resetPagination, setResetPagination] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedExchangeRelay, setSelectedExchangeRelay] = useState<ExchangeRelay | null>(null);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);

  const handleViewOfferDetails = (uid: string) => navigate(`/case/${uid}/offerDetail`);

  useEffect(() => {
    const subscribeToCases = (caseUids: string[]) => {
      // Set up the real-time listener for cases
      const unsubscribe = carrierCaseManagerFetchCases(
        caseUids,
        (updatedCases) => {
          setCases(updatedCases); 
        }
      );
  
      return unsubscribe;
    };
  
    const subscribeToRelays = () => {
      const batchSize = 500;
      if (userData?.carrierCode) {
        // Set up the real-time subscription for relays
        const unsubscribe = carrierCaseManagerFetchRelays(
          userData.carrierCode,
          batchSize,
          (exchangeRelays) => {
            setRelays(exchangeRelays);
  
            // Extract caseUids and subscribe to real-time updates for cases
            const caseUids = exchangeRelays.map((relay) => relay.caseUid).filter((uid): uid is string => !!uid);
            if (caseUids.length > 0) {
              const casesUnsubscribe = subscribeToCases(caseUids);
              return () => casesUnsubscribe();
            }
            setLoading(false); 
          }
        );
        return unsubscribe;
      }
  
      setLoading(false);
      return () => {};
    };
  
    if (userData?.isCarrier && userData?.carrierCode?.length) {
      const relaysUnsubscribe = subscribeToRelays();
      return () => relaysUnsubscribe();
    } else {
      setLoading(false);
    }
  }, [userData]);

  const handleViewOffer = (caseUid: string) => {
    const relay = relays.find((relay) => relay.caseUid === caseUid);
    const caseData = cases.find((c) => c.uid === caseUid);

    if (relay && caseData) {
      setSelectedCase(caseData);
      setSelectedExchangeRelay(relay);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedExchangeRelay(null); // Reset selected relay ID
  };

  const filteredCases = cases.filter((caseItem: any) =>
    caseItem.lastName?.toLowerCase().includes(filterText.toLowerCase())
  );

  const isOfferComplete = (caseUid: string) => {
    const relay = relays.find((relay) => relay.caseUid === caseUid);
    return ( !!relay?.offerDetails || !!relay?.tentativeOffer || !!relay?.offerAmount );
  };

  const columns: any[] = [
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {  
      name: 'Summary Status',
      cell: (row: { summaryStatus: string; appId: string }) => (
        row.summaryStatus === SummaryStatus.COMPLETED ? (
          <a href={`/case/${row.appId}/summary`}>
            {row.summaryStatus}
          </a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Offer',
      cell: (row: { uid: string }) => (
        <div className="left-align">
          <button onClick={() => handleViewOffer(row.uid)} 
            className={`inquiry-button ${isOfferComplete(row.uid) ? '' : 'incomplete-status'}`}
          >
            { isOfferComplete(row.uid) ? 'Revisit' : 'Start'} 
          </button>
        </div>
      ),
    },
    {
      name: 'Offer Details',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { row.offeredRelayIds?.length > 0 && isOfferComplete(row.uid) &&
            <button onClick={() => handleViewOfferDetails(row.uid)} className={`inquiry-button`}>
              View ({row.offeredRelayIds?.length || 0})
            </button>
          }
        </div>
      ),
    }
  ];

  const subHeaderComponentMemo = useMemo(() => {
		const clearFilter = () => {
			if (filterText) {
				setResetPagination(!resetPagination);
				setFilterText('');
			}
		};

		return (
			<>
				<input
					id="search"
					type="text"
					placeholder="Filter By Last Name"
					aria-label="Search Input"
					value={filterText}
					onChange={e => setFilterText(e.target.value)}
					className="search-input"
				/>
				<Button type="button" onClick={clearFilter} className="reset-button">X</Button>
			</>
		);
	}, [filterText, resetPagination]);

  return (
    <div className='casemanager-container'>      
      {loading && <h1>Loading...</h1>}
      {!loading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Offer Manager</h2>
          </div>
          <DataTable
            columns={columns}
            data={filteredCases}
            pagination
            highlightOnHover
            paginationPerPage={10}
            subHeader
			      subHeaderComponent={subHeaderComponentMemo}
          />
        </div>
      }

      <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
            { selectedExchangeRelay && 
              <OfferDetail 
                exchangeRelay={selectedExchangeRelay}
                firstName={selectedCase?.firstName || ""}
                lastName={selectedCase?.lastName  || ""} 
                setModalOpen={setModalOpen}
              />
            }
          </Box>
        </Modal>
      
    </div>
  );
};

export default CarrierCaseManager;
