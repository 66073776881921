import { db } from '../firebaseConfig';
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  limit,
  onSnapshot,
  getDocs,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { useQuery, useQueryClient } from 'react-query';
import { Case } from '../models/Case/case';
import { REQUEST_STATUS } from '../types/feedbackTypes';
import { CaseManagerSearchRequest } from '../types/caseSearchRequest';

const collectionRef = collection(db, 'cases');


// Soft delete a case
export const deleteCase = async (caseId: string): Promise<void> => {
  try {
    const caseRef = doc(collectionRef, caseId);
    await updateDoc(caseRef, { isDeleted: true });
  } catch (error) {
    console.error("Error deleting document: ", error);
    throw error;
  }
};

export const getCaseByAppId = async (appId: string): Promise<Case | null> => {
  try {
    const q = query(collectionRef, where("appId", "==", appId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const caseDoc = querySnapshot.docs[0];
      return caseDoc.data();
    } else {
      console.log("No case found with the given appId");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    throw error;
  }
};


export const carrierCaseManagerFetchCases = (
  caseUids: string[],
  callback: (cases: Case[]) => void
): (() => void) => {
  const casesRef = collection(db, 'cases');
  const casesQuery = query(casesRef, where('__name__', 'in', caseUids));

  // Set up the onSnapshot listener
  const unsubscribe = onSnapshot(casesQuery, (snapshot) => {
      const cases: Case[] = snapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      })) as Case[];

      // Call the callback with the updated data
      callback(cases);
    }
  );

  // Return the unsubscribe function to stop listening
  return unsubscribe;
};


export const fetchFilteredDataFromFirestore = (
  filters: CaseManagerSearchRequest,
  currentPage: number,
  lastVisible: any,
  onUpdate: (data: Case[], lastVisible: any) => void
): () => void => {
  // Primary query
  let q = query(collection(db, 'cases'));
  q = query(q, where('isDeleted', '==', false));

  if (filters.userEmail && filters.showOrgCases) {
    const emailDomain = filters.userEmail.substring(filters.userEmail.indexOf('@'));
    q = query(q, where('agentDomain', '==', emailDomain));
  }

  if (filters.userEmail && !filters.showOrgCases) {
    q = query(q, where('agentEmail', '==', filters.userEmail));
  }

  if (filters.caseStatus) {
    q = query(q, where('caseStatus', '==', filters.caseStatus));
  }

  if (filters.firstName.length > 1) {
    q = query(q, where('firstNameLower', '==', filters.firstName.toLowerCase()));
  }

  if (filters.lastName.length > 1) {
    q = query(q, where('lastNameLower', '==', filters.lastName.toLowerCase()));
  }

  q = query(q, orderBy('createdDate', 'desc'));

  // Calculate the starting point for the page
  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }
  
  q = query(q, limit(filters.batchSize));

  // Secondary query
  const query2 = query(
    collection(db, 'cases'),
    where('feedbackRequest.requestedOf', '==', filters.userEmail),
    where('feedbackRequest.feedbackStatus', '==', REQUEST_STATUS.REQUESTED),
    orderBy('createdDate', 'desc'),
    limit(filters.batchSize)
  );

  const combinedDocs = new Map<string, Case>();

  // Listener for the first query
  const unsubscribe1 = onSnapshot(q, (snapshot1) => {
    snapshot1.forEach((doc) => {
      combinedDocs.set(doc.id, { uid: doc.id, ...doc.data() } as Case);
    });

    // Listener for the second query
    const unsubscribe2 = onSnapshot(query2, (snapshot2) => {
      snapshot2.forEach((doc) => {
        combinedDocs.set(doc.id, { uid: doc.id, ...doc.data() } as Case);
      });

      // Update callback with combined results
      const lastVisible = snapshot1.docs[snapshot1.docs.length - 1]; // Last document for the current page
      onUpdate(Array.from(combinedDocs.values()), lastVisible);
    });

    // Return unsubscribe for both queries
    return () => {
      unsubscribe1();
      unsubscribe2();
    };
  });

  return unsubscribe1;
};

export const useFilteredData = (
  filters: CaseManagerSearchRequest,
  currentPage: number,
  lastVisible: any,
  setLastVisible: React.Dispatch<React.SetStateAction<any>>
) => {
  const queryClient = useQueryClient();

  const fetchFilteredData = () => {
    return new Promise<{ caseData: Case[]; hasNextPage: boolean }>((resolve) => {
      let hasNextPage = false;

      const unsubscribe = fetchFilteredDataFromFirestore(
        filters,
        currentPage,
        lastVisible,
        (caseData, lastVisible) => {
          if (caseData.length > 0) {
            setLastVisible(lastVisible);
          }

          queryClient.setQueryData(['filteredData', filters, currentPage], {
            caseData,
            hasNextPage,
          });

          // Check if there's a next page (based on lastVisible or data size)
          hasNextPage = caseData.length === filters.batchSize;
          resolve({ caseData, hasNextPage });
        }
      );

      return unsubscribe;
    });
  };

  return useQuery(['filteredData', filters, currentPage], fetchFilteredData, {
    enabled: false, // Manually controlled
  });
};



// export const useFilteredData = (filters: CaseManagerSearchRequest) => {
//   const queryClient = useQueryClient();

//   const fetchFilteredData = () => {
//     return new Promise<Case[]>((resolve) => {
//       const unsubscribe = fetchFilteredDataFromFirestore(filters, (data) => {
//         // Update the React Query cache with new data
//         queryClient.setQueryData(['filteredData', filters], data);
//       });

//       resolve([]);
//       return unsubscribe;
//     });
//   };

//   // React Query hook with manual refetch
//   return useQuery(['filteredData', filters], fetchFilteredData, {
//     enabled: false, // Disable automatic fetching; rely on manual refetch
//   });
// };

