import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Carrier } from '../../../models/carrier';
import { addDoc, collection, doc, getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { ExchangeRelay } from '../../../models/exchangeRelay';
import { Case } from '../../../models/Case/case';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import "./adminsendqueue.css";
import { carrierContacts } from '../../../types/carrierContacts';
import OfferDetail from '../../../components/OfferDetail/offerdetail';
import toast from 'react-hot-toast';
import ExternalShop from '../../../components/ExternalShop/externalshop';

const AdminSendQueue = () => {
  const { uid, appId } = useParams<{ uid: string; appId: string; }>();
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [exchangeRelays, setExchangeRelays] = useState<ExchangeRelay[]>([]);
  const [caseData, setCaseData] = useState<Case | null>(null); 

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedExchangeRelay, setSelectedExchangeRelay] = useState<ExchangeRelay | null>(null);
  const [shoppedModalOpen, setShoppedModalOpen] = useState(false);

  // Fetch carriers and sort them alphabetically
  const fetchCarriers = useCallback(() => {
    setCarriers([...carrierContacts].filter(carrier => carrier.active).sort((a, b) => a.name.localeCompare(b.name)));
  }, []);

  // Fetch case data
  const fetchCaseData = useCallback(async () => {
    if (!uid) return;

    try {
      const caseDoc = await getDoc(doc(db, "cases", uid));
      if (caseDoc.exists()) {
        const caseInfo = caseDoc.data() as Case;
        setCaseData(caseInfo);
      } else {
        console.warn("No case data found!");
      }
    } catch (error) {
      console.error("Error fetching case data:", error);
    }
  }, [uid]);

  const subscribeToExchangeRelays = useCallback(() => {
    if (!uid) return () => {};

    const relayQuery = query(collection(db, "exchangeRelay"), where("caseUid", "==", uid));
    const unsubscribe = onSnapshot(relayQuery, (snapshot) => {
      const relays = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ExchangeRelay));
      setExchangeRelays(relays);
    });

    return unsubscribe;
  }, [uid]);
  
  useEffect(() => {
    fetchCarriers();
    fetchCaseData();
    const unsubscribe = subscribeToExchangeRelays();

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [fetchCarriers, fetchCaseData, subscribeToExchangeRelays]);

  const handleViewExchangeRelay = (relayUid?: string) => {
    const relay = exchangeRelays.find((relay) => relay.id === relayUid);
    if (relay) {
      setSelectedExchangeRelay(relay);
      setModalOpen(true);
    } else {
      toast.error("Error: Exchange Relay not found.");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedExchangeRelay(null); // Reset selected relay ID
  };

  const addExchangeRelay = async (carrierCode: string, exchangeRelay: ExchangeRelay) => {
    try {
      await addDoc(collection(db, "exchangeRelay"), exchangeRelay);
    } catch (error) {
      console.error("Error adding ExchangeRelay record:", error);
    }
  };

  const handleAddShopped = () => {
    setShoppedModalOpen(true);
  };

  const handleCloseShopped = () => {
    setShoppedModalOpen(false);
  };

  const isRelayComplete = (relay: ExchangeRelay) => {
    return (
      !!relay.offerDetails || 
      !!relay.tentativeOffer ||
      !!relay.offerAmount
    );
  };

  return (
    <div className="container">
      <div className='data-table'>
        <header className="form-section">
          <h2>Admin Shopping List</h2>
          {caseData && (
            <div className="shopping-table-header">
              <p className='send-queue-subheader'><span  className='subheader-bold'>{caseData.firstName} {caseData.lastName} </span>{caseData.dob}</p>
              <button onClick={() => handleAddShopped()}  className="shopped-button">Add Shopped Carrier</button>
            </div>
          )}
        </header>

        <table className="send-queue-table">
          <thead>
            <tr>
              <th>Carrier Name</th>
              <th>Status</th>
              <th>Offer</th>
            </tr>
          </thead>
          <tbody>
            {carriers.map((carrier) => {
              const relay = exchangeRelays.find((r) => r.carrierCode === carrier.code);

              if (!relay) return null;

              return (
                <tr key={carrier.code} className="table-row">
                  <td className='carrier-column'>{carrier.name}</td>
                  <td className='status-column'>{relay.status} { relay.sendDate || "" }</td>
                  <td>
                    <button onClick={() => handleViewExchangeRelay(relay.id)}
                      className={`inquiry-button ${ isRelayComplete(relay) ? '' : 'incomplete-status'}`}
                    >
                      { isRelayComplete(relay) ? 'Revisit' : 'Start'}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
            { selectedExchangeRelay && 
              <OfferDetail 
                exchangeRelay={selectedExchangeRelay}
                firstName={caseData?.firstName || ""}
                lastName={caseData?.lastName  || ""} 
                setModalOpen={setModalOpen}
              />
            }
          </Box>
        </Modal>

        <Modal open={shoppedModalOpen} onClose={handleCloseShopped}>
          <Box sx={{ 
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
            <ExternalShop 
              caseUid={uid || ''}
              appId={appId || ''}
              setModalOpen={setShoppedModalOpen}
              addExchangeRelay={addExchangeRelay}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default AdminSendQueue;
