export interface User {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  agency: string;
  carrierRole?: string;
  exchangePartner?: string;
  createdAt: Date;
  roles?: string[];
  isCarrier?: boolean;
  carrierCode?: string;
  isBga?: boolean;
}

// Type Guard Function
export const isUser = (data: any): data is User => {
  return (
    typeof data === 'object' &&
    typeof data.uid === 'string' &&
    typeof data.firstName === 'string' &&
    typeof data.lastName === 'string' &&
    typeof data.email === 'string' &&
    typeof data.agency === 'string' &&
    typeof data.createdAt === 'object'
  );
};