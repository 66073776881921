import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { object, string, TypeOf } from 'zod';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { Box, Container, FormControl, Typography } from '@mui/material';
import FormInput from "../../../components/FormInputs/FormInput";
import { LinkItem } from "../../../components/LinkItem";
import { registerUser } from "../../../services/firebaseService";

import './register.css';
import { useParams } from "react-router-dom";

const registerSchema = object({
  firstname: string().min(1,'First name is required').max(100),
  lastname: string().min(1,'Last name is required').max(100),
  agency: string(),
  email: string()
    .min(1,'Email address is required')
    .email('Email Address is invalid'),
  password: string()
    .min(1,'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: string().min(1,'Please confirm your password'),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Passwords do not match',
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const Register = () => {  
  const { partner } = useParams(); 
  const [exchangePartner, setExchangePartner] = useState('');
  const [carrierRole, setCarrierRole] = useState("");
  const [isCarrier, setIsCarrier] = useState(false);

  const [isLoading, setisLoading] = useState(false);
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (partner === 'ain') {
      setExchangePartner('AIN');
    } else if (partner === 'ls') {
      setExchangePartner('LS');
    } else if (partner === 'ain-carrier') {
      setExchangePartner('AIN');
      setIsCarrier(true);
    } else if (partner === 'ls-carrier') {
      setExchangePartner('LS');
      setIsCarrier(true);
    } else {
      // Redirect to home if partner is not 'ain' or 'ls'
      navigate('/');
    }
  }, [partner, navigate]);

  const {
    handleSubmit,
  } = methods;

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (registerInput) => {    
    setisLoading(true);
    await registerUser(registerInput, exchangePartner, carrierRole, isCarrier);
    toast.success('Registration successful! Please Login.');
    setisLoading(false);
    navigate('/login');
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCarrierRole(e.target.value)
  };

  return (
    <Container maxWidth={false} className="register-container" sx={{
      display: 'flex',
    }}>
      <Box className="register-box"> 
        <FormProvider {...methods}>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmitHandler)}
            autoComplete='off'
            className="register-form-box"
          >
            <div className="typography-register-h2">
              Sign up 
            </div>

            <Typography className="typography-login-links">
              Already have an account?{' '} <LinkItem to='/login'>Login Here</LinkItem>
            </Typography>

            <FormInput name='firstname' label='First Name' />
            <FormInput name='lastname' label='Last Name' />
            <FormInput name='email' label='Email Address' type='email' />
            {!isCarrier && <FormInput name='agency' label='Agency' />}
            {isCarrier && 
              <FormControl fullWidth sx={{ mb: 1 }}>
                <Typography
                  variant='body2'
                  sx={{ color: '#0c2441', mb: 0, fontSize: '1.1rem' }}
                >
                  Role
                </Typography>
                  <select className="carrier-role" value={carrierRole} onChange={handleRoleChange}>
                    <option value=""></option>
                    <option value="Distribution">Distribution</option>
                    <option value="Operations">Operations</option>
                    <option value="Underwriting">Underwriting</option>
                  </select>
              </FormControl>
            }
            <FormInput name='password' label='Password' type='password' />
            <FormInput
              name='passwordConfirm'
              label='Confirm Password'
              type='password'
            />

            <LoadingButton
              className="loading-button"
              variant='contained'
              fullWidth
              disableElevation
              type='submit'
              loading={isLoading}
            >
              Sign Up
            </LoadingButton>
          </Box>
        </FormProvider>
      </Box>
    </Container>
  );


};

export default Register;