import { SetStateAction, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { Timestamp } from 'firebase/firestore';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "./externalshop.css";
import { ExchangeRelay } from '../../models/exchangeRelay';
import toast from 'react-hot-toast';
import { carrierContacts } from '../../types/carrierContacts';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { Carrier } from '../../models/carrier';
import { getShortDate } from '../../utils/dateTime';

interface OfferDetailProps {
  caseUid: string; 
  appId: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
  addExchangeRelay: (carrierCode: string, exchangeRelay: ExchangeRelay) => Promise<void>;
}

type ShoppedCarrier = { 
  carrierCode: string;
  shoppedDate: Timestamp;
}

const schema = Yup.object().shape({
  carrierCode: Yup.string().required('This field is required'),
  shoppedDate: Yup.string()
    .required('Shopped Date is required')
    .test('is-valid-date', 'Shopped Date must be a valid date', (value) => {
      return value ? !isNaN(Date.parse(value)) : false; // Validate the string can be parsed into a Date
    }),
});

const ExternalShop: React.FC<OfferDetailProps> = ({ caseUid, appId, setModalOpen, addExchangeRelay }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>();
  const [carriers, setCarriers] = useState<Carrier[]>([]);

  const { userData } = useAppSelector(selectAuth);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<ShoppedCarrier>({
    resolver: yupResolver(schema) as any,
  });

  useEffect(() => {
    const fetchCarriers = () => {
      const sortedCarriers = [...carrierContacts].filter(item => item.active).sort((a, b) => a.name.localeCompare(b.name));
      const exchangePartnerFilter = userData?.exchangePartner ?? "AIN";
      setCarriers(sortedCarriers.filter(i => i.exchangePartner === exchangePartnerFilter));
    };

    fetchCarriers();
    setLoading(false);
  }, [setValue, userData]);

  const onSubmit = async (shoppedCarrier: ShoppedCarrier) => {
    const carrier = carrierContacts.find(carrier => carrier.code === shoppedCarrier.carrierCode)
    const [year, month, day] = shoppedCarrier.shoppedDate.toString().split('-').map(Number);
    const shopDate = new Date(year, month - 1, day)

    if(carrier) {
      const exchangeRelay: ExchangeRelay = {
        caseUid: caseUid,
        appId: appId,
        agentEmail: userData?.email,
        carrierCode: carrier.code,
        carrierEmail: carrier.email,
        sendDate: getShortDate(shopDate),
        status: "Shopped outside",
        createdDate: Timestamp.now(), 
      };

      addExchangeRelay(carrier.code, exchangeRelay)
      setModalOpen(false)
      toast.success('Shopping list successfully updated!');
    } else 
    {
      setError("Something went wrong.  Try again or contact support@apeirongate.com")
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className='case-container'>
      {!loading &&
        <>
          <div className="form-section">
            <h3>
            Add Previously Shopped Carrier
            </h3>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)} className="external-shop-form">
            <div className="form-row">
      
              <div className="form-group">
                <label htmlFor="carrier">Carrier</label>
                <select id="carrierCode" {...register('carrierCode')} >
                  <option value="">Select Carrier</option>
                  {carriers.map((carrier) => (
                    <option key={carrier.code} value={carrier.code}>
                      {carrier.name}
                    </option>
                  ))}
                </select>
                {errors.carrierCode && <span style={{ color: 'red' }}>{errors.carrierCode.message}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="shoppedDate">Shopped Date</label>
                <input id="shoppedDate" type="date" {...register('shoppedDate')} />
                {errors.shoppedDate && <span style={{ color: 'red' }}>{errors.shoppedDate.message}</span>}
              </div>
            </div>

            <button type="submit">Submit</button>
          </form>
        </>
      }
    </div>
  );
};

export default ExternalShop;

