import { collection, query, where, getDocs, DocumentData, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Inquiry } from '../models/Case/inquiry';

export const fetchInquiriesByCaseUid = async (caseUid: string): Promise<Inquiry[]> => {
  try {
    const inquiriesCollectionRef = collection(db, 'inquiries');
    const q = query(inquiriesCollectionRef, where('caseUid', '==', caseUid));
    const querySnapshot = await getDocs(q);

    const inquiries: Inquiry[] = [];
    querySnapshot.forEach((doc: DocumentData) => {
      inquiries.push(doc.data() as Inquiry);
    });

    return inquiries;
  } catch (error) {
    console.error('Error fetching inquiries:', error);
    throw error;
  }
};

export const saveOrUpdateInquiry = async (caseUid: string, inquiryData: Partial<Inquiry>) => {
  const inquiryCollectionRef = collection(db, 'inquiries');
  const q = query(inquiryCollectionRef, where('caseUid', '==', caseUid));
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    // Update the existing Inquiry document
    const inquiryDoc = querySnapshot.docs[0]; // caseUid is unique, take the first result
    const inquiryDocRef = doc(db, 'inquiries', inquiryDoc.id);
    await updateDoc(inquiryDocRef, inquiryData);
  } else {
    // Create a new Inquiry document
    const newInquiryDocRef = doc(inquiryCollectionRef);
    await setDoc(newInquiryDocRef, { ...inquiryData, caseUid });
  }
};