import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case } from '../../../models/Case/case';
import { Inquiry } from '../../../models/Case/inquiry';

interface NarrativeSectionProps {
  register: UseFormRegister<Case & Inquiry>;
  errors: FieldErrors<Case & Inquiry>;
}

const NarrativeSection: React.FC<NarrativeSectionProps> = ({ register, errors }) => (
  <div className="form-section">
    <h3>Narrative - Design</h3>
    <div className="form-row">
      <div className="form-group-wide">
        <label htmlFor="narrative">Please provide case design, 1035 details, trust owned, key person, etc.</label>
        <textarea id="narrative" {...register('narrative')} />
        {errors.narrative && <span style={{ color: 'red' }}>{errors.narrative.message}</span>}
      </div>
    </div>
  </div>
);

export default NarrativeSection;
