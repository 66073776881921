import { Timestamp } from "firebase/firestore";

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export const getDefaultMonthYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const priorMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1; // January wraps to December
  const defaultYear = currentYear;

  return { defaultMonth: priorMonth, defaultYear };
};

export const years = () => { 
  const { defaultYear } = getDefaultMonthYear();
  return Array.from(
    { length: 4 },
    (_, i) => defaultYear - 3 + i // Generate years around the current year
  );
}

export const getShortDateFormat = (date?: Timestamp) => {
  try {
    const dateFormat = date?.toDate()
    const formattedDate = dateFormat
      ? `${(dateFormat.getMonth() + 1).toString().padStart(2, "0")}/${dateFormat.getDate().toString().padStart(2, "0")}/${dateFormat.getFullYear()}` 
      : '';
    return formattedDate;
  } catch (error) {
    return '';
  }
}

export const getShortDate = (date?: Date) => {
  try {
    const formattedDate = date
      ? `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}` 
      : '';
    return formattedDate;
  } catch (error) {
    return '';
  }
}