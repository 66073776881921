import { useEffect, useState } from 'react';
import { Case } from '../../../models/Case/case';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AgencySection from './agencySection';
import ApplicantSection from './applicanSection';
import InformalGoalsSection from './informalGoalsSection';
import NarrativeSection from './narrativeSection';
import PriorApplicationInquirySection from './priorApplicationInquirySection';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { fetchInquiriesByCaseUid, saveOrUpdateInquiry } from '../../../services/inquiry-data.service';

import "./casedetail.css";
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { getAgency } from '../../../services/agencyMapping-data.service';
import { Inquiry } from '../../../models/Case/inquiry';
import { InquiryStatus } from '../../../types/caseTypes';

const schema = Yup.object().shape({
  agency: Yup.string().required('This field is required'),
  agentEmail: Yup.string().email('Invalid email').required('This field is required'),
  agentfirstName: Yup.string().required('This field is required'),
  agentlastName: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  dob: Yup.string().required('This field is required'),
  productType: Yup.string().required('This field is required'),
  faceAmount: Yup.string().required('This field is required'),
  targetPremium: Yup.string().required('This field is required'),
  targetRating: Yup.string().required('This field is required'),
  priorInquiry: Yup.boolean().required('This field is required'),
  isNewYork: Yup.boolean(),
});

const CaseDetail = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);  
  const { uid } = useParams<{ uid: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [priorInquiryValue, setPriorInquiryValue] = useState<boolean>(false);
  const [retentionOnlyValue, setRetentionOnlyValue] = useState<boolean>();
  const [premiumFinanceValue, setPremiumFinanceValue] = useState<boolean>();
  const [survivorshipValue, setSurvivorshipValue] = useState<boolean>();
  const [isNewYorkValue, setIsNewYorkValue] = useState<boolean>(false);

  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm<Case & Inquiry>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      priorInquiry: false, // Ensure default value is a boolean
      isNewYork: false,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "priorInquiries"
  });

  useEffect(() => {
    const fetchData = async () => {
      if (uid) {
        // Fetch Case data
        const caseDocRef = doc(db, 'cases', uid);
        const caseDocSnap = await getDoc(caseDocRef);

        if (caseDocSnap.exists()) {
          const caseData = caseDocSnap.data() as Case;
          // Populate form fields with case data
          Object.keys(caseData).forEach(key => {
            setValue(key as keyof Case, caseData[key as keyof Case]);
          });

          if (!caseData.agency && userData?.email) {
            const agencyMapping = await getAgency(userData?.email)
            const agency = agencyMapping ?? userData?.agency;
            setValue('agency', agency)
          }
          if (!caseData.agentEmail) {
            setValue('agentEmail', userData?.email)
          }
          if (!caseData.agentfirstName) {
            setValue('agentfirstName', userData?.firstName)
          }
          if (!caseData.agentfirstName) {
            setValue('agentlastName', userData?.lastName)
          }
        }

        // Fetch Inquiry data
        const inquiries = await fetchInquiriesByCaseUid(uid);
        inquiries.forEach(inquiryData => {
          Object.keys(inquiryData).forEach(key => {
            setValue(key as keyof Inquiry, inquiryData[key as keyof Inquiry]);
          });
          setPriorInquiryValue(inquiryData.priorInquiry ?? false);
          if (inquiryData.priorInquiry && (!inquiryData.priorInquiries || inquiryData.priorInquiries.length === 0)) {
            append({ carrier: "", offer: "", placed: "" });
          }

          setRetentionOnlyValue(inquiryData.retentionOnly ?? undefined);
          setPremiumFinanceValue(inquiryData.premiumFinance ?? undefined);
          setSurvivorshipValue(inquiryData.survivorship === "Yes");
        });

        setLoading(false);
      }
    };

    fetchData();
  }, [uid, setValue, append, userData]);

  const handlePriorInquiryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setPriorInquiryValue(value);
    setValue('priorInquiry', value);
    if (value && fields.length === 0) {
      append({ carrier: "", offer: "", placed: "" });
    }
  };

  const handleRetentionOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setRetentionOnlyValue(value);
    setValue('retentionOnly', value);
  };

  const handlePremiumFinanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === 'true';
    setPremiumFinanceValue(value);
    setValue('premiumFinance', value);
  };

  const handleNewYorkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked; // Get boolean value
    setIsNewYorkValue(isChecked); 
    setValue('isNewYork', isChecked, { shouldValidate: true });
  };

  const handleSurvivorshipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.value === "true";
    setSurvivorshipValue(isChecked); 
    setValue('survivorship', event.target.value === "true" ? "Yes" : "No", { shouldValidate: true });
  };

  const onSubmit: SubmitHandler<Case & Inquiry> = async data => {
    if (userData?.isBga) {
      navigate('/bgacasemanager')
    }
    
    if (!uid) {
      throw new Error('UID is required and must be a non-empty string.');
    }

    const { appId, agency, agentEmail, agentfirstName, agentlastName, firstName, lastName, dob, inquiryStatus, summaryStatus, isDeleted, isNewYork, 
      agent1, contact, offeredRelayIds, relayIds, agentDomain, feedbackRequest, ...inquiryData } = data;
    // Save Case data
    const caseDocRef = doc(db, 'cases', uid);
    const caseData: Partial<Case> = {
      appId,
      agency,
      agentEmail,
      agentfirstName,
      agentlastName,
      agent1,
      contact,
      firstName,
      lastName,
      dob,
      isNewYork,
      inquiryStatus: InquiryStatus.COMPLETED,
      updatedDate: Timestamp.now()
    };

    // Remove undefined fields
    const filteredCaseData = Object.fromEntries(
      Object.entries(caseData).filter(([_, value]) => value !== undefined)
    );

    await updateDoc(caseDocRef, filteredCaseData);

    await saveOrUpdateInquiry(uid, inquiryData);

    navigate('/casemanager')
  };

  return (
    <div className='case-container'>
      {loading && <h1>Loading...</h1>}
      {!loading &&
        <>
          <div className="form-section">
            <h2>Informal Inquiry</h2>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="case-form">
            <ApplicantSection 
              register={register} 
              errors={errors} 
              isNewYorkValue={isNewYorkValue}
              handleNewYorkChange={handleNewYorkChange}  
              exchangePartner={userData?.exchangePartner ?? 'AIN'}
            />
            <AgencySection register={register} errors={errors} />
            <InformalGoalsSection 
              register={register} 
              errors={errors} 
              retentionOnlyValue={retentionOnlyValue}
              handleRetentionOnlyChange={handleRetentionOnlyChange}
              premiumFinanceValue={premiumFinanceValue}
              handlePremiumFinanceChange={handlePremiumFinanceChange}
              survivorshipValue={survivorshipValue}
              handleSurvivorshipChange={handleSurvivorshipChange}
            />
            <PriorApplicationInquirySection
              register={register}
              errors={errors}
              priorInquiryValue={priorInquiryValue}
              handlePriorInquiryChange={handlePriorInquiryChange}
              fields={fields}
              append={append}
              remove={remove}
            />
            <NarrativeSection register={register} errors={errors} />
            { !userData?.isBga && <button type="submit">Submit</button> }
          </form>
        </>
      }
    </div>
  );
};

export default CaseDetail;
