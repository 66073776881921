import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectAuth } from "../../redux/slices/authSlice";

export const RequireAuth = ({ allowedRoles, excludedRoles = [], }: { allowedRoles: string[], excludedRoles?: string[]; }) => {
  const { userData } = useAppSelector(selectAuth);

  const location = useLocation();

  let authorized = true;
  if (allowedRoles.length > 0) {
    authorized = allowedRoles.some(allowedRole => userData?.roles?.includes(allowedRole)) ||
      (allowedRoles.includes('carrier') && userData?.isCarrier === true) ||
      (allowedRoles.includes('bga') && userData?.isBga === true);
  }
  
   // Check if the user has any of the excluded roles
   if (excludedRoles.length > 0) {
    const isExcluded = excludedRoles.some(excludedRole => userData?.roles?.includes(excludedRole)) ||
    (excludedRoles.includes('carrier') && userData?.isCarrier === true)  ||
    (excludedRoles.includes('bga') && userData?.isBga === true);
    authorized = authorized && !isExcluded;
  }

  return userData && authorized ? (
    <Outlet />
  ) : userData && !authorized ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}