import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  limit,
  getDocs
} from 'firebase/firestore';
import { getEmailDomain } from '../utils/email';

const agencyMappingRef = collection(db, 'agencyMappings');

export const getAgency = async (email: string): Promise<string | undefined> => {
  // 30 days in milliseconds
  const emailDomain = getEmailDomain(email);
  
  const agencyQuery = query(
    agencyMappingRef,
    where('agentDomain', '==', emailDomain),
    limit(1)
  );

  const snapshot = await getDocs(agencyQuery);

  // Extract the `agency` field from the first document if it exists
  const agency: string | undefined = snapshot.docs.length > 0 ? snapshot.docs[0].data().agency : undefined;
  return agency;
};