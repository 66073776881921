export const fetchSignedUrl = async (fileName: string): Promise<string> => {
  try {
    const response = await fetch(`https://us-central1-apeirongateportal.cloudfunctions.net/Summary-report/generateSignedUrl?fileName=${fileName}`);
    
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("The requested file was not found (404).");
      }
      throw new Error(`Request failed with status: ${response.status}`);
    }
    
    const data = await response.json();
    return data.url;
  } catch (error) {
    throw error;
  }
};
