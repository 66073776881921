export enum SummaryStatus {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
}

export enum InquiryStatus {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
}

