import * as XLSX from "xlsx";
import { InvoiceBackupItem } from "../../../models/invoiceitem";


export const exportToExcel = (invoiceItems: InvoiceBackupItem[], fileName: string) => {
  // Add the summary table
  const summaryHeaders = ["Total Cost", "Total Trial Packages Received", "Total Face Amount", "Total Associated Target Premium"];
  const summaryData = [
    [
      invoiceItems.length * 30,
      invoiceItems.length,
      getTotalFace(invoiceItems),
      getTotalTargetPremium(invoiceItems),
    ],
  ];

  // Add the detail table
  const detailHeaders = ["Agency", "Last Name", "Policy ID", "Target Premium", "Face Amount", "Submit Date"];
  const detailData = invoiceItems.map((item) => [
    item.agency || "N/A",
    item.lastName || "N/A",
    item.policyId || "N/A",
    item.targetPremium || "N/A",
    item.faceAmount || "N/A",
    item.submitDate || "N/A",
  ]);

  // Combine summary and detail tables
  const combinedData = [
    // Summary Table
    ["Summary Table"],
    summaryHeaders,
    ...summaryData,
    [], // Empty row for spacing
    ["Detail Table"],
    detailHeaders,
    ...detailData,
  ];

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice Data");

  // Export to Excel
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
  
const getTotalFace = (invoiceItems: InvoiceBackupItem[]) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    const faceAmount = Number(item.faceAmount);
    if (!isNaN(faceAmount)) {
      total = total + faceAmount;
    } else {
      return 0;
    }
  })

  return total;
};

const getTotalTargetPremium = (invoiceItems: InvoiceBackupItem[]) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    const targetPremium = Number(item.targetPremium);
    if (!isNaN(targetPremium)) {
      total = total + targetPremium;
    } else {
      return 0;
    }
  })

  return total;
};