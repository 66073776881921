import React from 'react';
import { InvoiceBackupItem } from '../../../models/invoiceitem';

interface InvoiceTableProps {
  invoiceItems: InvoiceBackupItem[];
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoiceItems }) => {
  return (
    <div className="invoice-table-wrapper">
      <table className="invoice-table">
        <thead>
          <tr>
            <th>Agency</th>
            <th>Last Name</th>
            <th>Policy ID</th>
            <th>Target Premium</th>
            <th>Face Amount</th>
            <th>Submit Date</th>
          </tr>
        </thead>
        <tbody>
          {invoiceItems.map((item, index) => (
            <tr key={index}>
              <td>{item.agency || ''}</td>
              <td>{item.lastName || ''}</td>
              <td>{item.policyId || ''}</td>
              <td>{item.targetPremium || ''}</td>
              <td>{item.faceAmount || ''}</td>
              <td>{item.submitDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
