import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Case } from '../../../models/Case/case';

interface ApplicantSectionProps {
  register: UseFormRegister<Case>;
  errors: FieldErrors<Case>;
  isNewYorkValue: boolean | undefined;
  handleNewYorkChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  exchangePartner: string;
}

const ApplicantSection: React.FC<ApplicantSectionProps> = ({ register, errors, isNewYorkValue, handleNewYorkChange, exchangePartner }) => (
  <div className="form-section">
    <h3>Applicant</h3>
    <div className="form-row">
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input id="firstName" {...register('firstName')} />
        {errors.firstName && <span style={{ color: 'red' }}>{errors.firstName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input id="lastName" {...register('lastName')} />
        {errors.lastName && <span style={{ color: 'red' }}>{errors.lastName.message}</span>}
      </div>
      <div className="form-group">
        <label htmlFor="dob">DOB</label>
        <input id="dob" type="date" {...register('dob')} />
        {errors.dob && <span style={{ color: 'red' }}>{errors.dob.message}</span>}
      </div>
    </div>
    { exchangePartner === 'AIN' &&
      <div className="form-row">
        <input
            type="checkbox"
            {...register('isNewYork')}
            checked={isNewYorkValue === true}
            onChange={handleNewYorkChange}
          />
          Does Applicant live in New York?
      </div>
    }
  </div>
);

export default ApplicantSection;
