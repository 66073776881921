import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useDropzone } from 'react-dropzone';

import "./caseupload.css";
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Utilitity/Spinner';
import FileList from './filelist';

interface FormData {
  firstName: string;
  lastName: string;
  dob: string;
  files: File[];
}

type FileEntry = {
  originalFileName: string;
  fileName: string;
};

const CaseUpload: React.FC = () => {  
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);

  // Initialize state with form data
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    dob: '',
    files: [],
  });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  
  const isSubmitDisabled = !isCheckboxChecked || formData.files.length === 0;

  // Handle input changes
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, });
  };

  // Set up the dropzone for file uploads
  const onDrop = (acceptedFiles: File[]) => {
    setFormData({
      ...formData,
      files: [...formData.files, ...acceptedFiles],
    });
  };

  // Handle file upload and submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const submissionData = await buildSubmissionData();
    if (!submissionData) return;  // Exit if an error occurs in file upload
    await publishCase(submissionData);
    setIsLoading(false);
  };

  // Create and populate submission data object
  const buildSubmissionData = async (): Promise<{ agentEmail: string; firstName: string; lastName: string; dob: string; files: FileEntry[] } | null> => {
    const submissionData = {
      agentEmail: userData?.email || "",
      firstName: formData.firstName,
      lastName: formData.lastName,
      dob: formData.dob,
      files: []
    };

    const fileUploadPromises = formData.files.map((file) => uploadFile(file, submissionData));
    await Promise.all(fileUploadPromises);
    return submissionData;
  };

  // Upload each file
  const uploadFile = async (file: File, submissionData: any) => {
    const bucketName = 'exchange-uploads';
    const data = new FormData();
    data.append('file', file);

    try {
      const response = await fetch(`https://us-central1-apeirongateportal.cloudfunctions.net/CS-uploadToFirebaseStorage/upload?bucketName=${bucketName}`, 
      {
        method: 'POST',
        body: data,
      });
      const result = await response.json();
      submissionData.files.push({ originalFileName: file.name, fileName: result.fileName });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('File upload failed. Please try again.');
      setIsLoading(false);
      return null;
    }
  };

  // Publish case data after all files are uploaded
  const publishCase = async (submissionData: any) => {
    try {
      const response = await fetch('https://us-central1-apeirongateportal.cloudfunctions.net/Case-publishCaseMessage/upload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result.message);

      toast.success('Case upload successful! It will appear in Trial Manager after processing.', { duration: 5000, });
      navigate('/casemanager');
    } catch (err) {
      toast.error('An unexpected error occurred. Please check files and try again.', { duration: 5000, });
    }
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = formData.files.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      files: updatedFiles,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className='upload-container'>
      {isLoading && <Spinner message="Processing... Please do not close or refresh the page." />}
      <div className="form-section">
        <h2>Case Upload</h2>
      </div>
      <form onSubmit={handleSubmit}  className="upload-form">
        <h3>Applicant</h3>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob">DOB</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        <h3>File Upload</h3>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`} >
          <input {...getInputProps({ accept: '.pdf, .tiff, .tif' })} />
          { isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p> }
        </div>

        <FileList files={formData.files} onDelete={(index) => handleFileDelete(index)} />

        <div className="form-row">
          <input type="checkbox" checked={isCheckboxChecked} onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} />
            I agree to the terms and conditions
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button"  disabled={isSubmitDisabled}>Submit</button>
      </form>
    </div>
  );
};

export default CaseUpload;
