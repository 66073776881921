import React, { SetStateAction } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FeedbackRequestForm from './feedbackrequest';

interface CaseFeedbackModalProps {
  open: boolean;
  onClose: () => void;
  caseUid: string;
  firstName: string;
  lastName: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
}

const CaseFeedbackModal: React.FC<CaseFeedbackModalProps> = ({
  open,
  onClose,
  caseUid,
  firstName,
  lastName,
  setModalOpen,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <FeedbackRequestForm
          caseUid={caseUid}
          firstName={firstName}
          lastName={lastName}
          setModalOpen={setModalOpen}
        />
      </Box>
    </Modal>
  );
};

export default CaseFeedbackModal;
